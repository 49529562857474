<script lang="ts" setup>
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore from 'swiper'
import 'swiper/css'
import type { IAdBlock } from '@/api/page'

const props = defineProps({
  data: {
    type: Array as PropType<IAdBlock[]>,
    default: []
  }
})
SwiperCore.use([Autoplay])
const storageUrl = import.meta.env.VITE_STORAGE_URL
</script>

<template>
  <section class="section">
    <div class="wrap1144">
      <swiper :autoplay="{
        delay: 3000
      }" :slides-per-view="1" :space-between="0" :speed="300" :loop="true" :pagination="true" class="swiper-list">
        <swiper-slide v-for="(item, index) in data" :key="index">
          <div class="swiper-list__box">
            <figure class="swiper-list__pic imh__opacity2" v-if="item.type === 'image'">
              <nuxt-link :to="item.url">
                <NuxtImg :src="`${storageUrl}/${item.image}`"></NuxtImg>
              </nuxt-link>
            </figure>
            <div class="swiper-list__pic" v-if="item.type === 'video'">
              <nuxt-link :to="item.url">
                <video autoplay loop muted playsinline>
                  <source :src="`${storageUrl}/${item.video}`">
                </video>
              </nuxt-link>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section {
  margin-bottom: 185px;

  @include max-media(767) {
    width: 100%;
    max-width: 275px;
    margin-bottom: 75px;
    margin-left: auto;
    margin-right: auto;
  }

  .wrap1144 {
    @include max-media(767) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.swiper-list {
  &__pic {
    img {
      display: block;
      width: 100%;
    }

    video {
      display: block;
      width: 100%;
    }
  }
}
</style>
